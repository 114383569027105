@font-face {
	font-family: "Mochiy Pop P One";
	src: url("../assets/fonts/MochiyPopPOne-Regular.ttf");
}

html,
body {
	font-size: 62.5%;
	margin: 0;
	font-family: "Mochiy Pop P One", -apple-system, BlinkMacSystemFont, "Segoe UI",
		"Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
		"Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

h1 {
	font-size: 2.4rem;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}
