@import "./colours.scss";

main {
	text-align: center;
	height: 100vh;
	width: 100vw;
	display: grid;
	grid-template-rows: minmax(7.5vh, 7rem) minmax(0, 1fr) 1vh;

	box-sizing: border-box;
	background: $background-colour;
	color: $text-colour;

	font-size: 1.6rem;
}

header {
	padding: 0.5rem 0.5rem 0.5rem 1rem;
	background: $accent-colour;
	border-bottom: 2px solid $border-colour;

	display: flex;
	align-items: center;

	min-width: 0;

	img {
		max-height: 100%;
		width: auto;
	}
}
