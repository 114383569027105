@use "sass:color";
@import "./colours.scss";

.search-page {
	width: 85vw;
	@media (min-height: 50rem) {
		width: 50vw;
	}

	@media (orientation: portrait) {
		max-width: 85vw;
	}
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 0 auto;

	h2 {
		@media (orientation: landscape) {
			align-self: flex-start;
		}
	}
}

.sources-container {
	margin: 1rem;
	input[type="checkbox"] {
		display: none;

		// The checkbox that we show
		// &:hover +.styled-checkbox {
		// }

		&:checked + .styled-checkbox {
			background: rgba($accent-colour, 80%);
			border: 1px solid $border-colour;
		}

		+ .styled-checkbox {
			transition: transform 0.07s ease-in-out;

			&:hover {
				cursor: pointer;
				transform: scale(0.98);
			}

			&:active {
				transform: scale(1);
			}

			@media screen and (any-pointer: coarse) {
				margin: 0.6rem 0.8rem;
			}

			display: inline-flex;
			margin: 0.3rem 0.4rem;
			background: rgba($accent-colour, 30%);
			border: 1px solid rgba(white, 0%);
			box-shadow: 2px 2px 1px rgba($border-colour, 0.15);
			border-radius: 15px;
			padding: 0.3rem 0.7rem;

			img {
				height: 2.5rem;
				width: 2.5rem;
			}

			span {
				line-height: 25px;
				text-transform: capitalize;
			}
		}
	}
}

.search-button {
	color: color.scale($border-colour, $blackness: 30%);
	font-size: 1.2rem;
	font-weight: 600;
	border: none;
	background: none;
	position: relative;
	transition: transform 0.1s ease-in-out;
	height: 10rem;
	width: 10rem;

	@media screen and (any-pointer: coarse) {
		padding: 1rem;
	}

	&:hover {
		cursor: pointer;
		transform: translateY(3px);
	}

	&:active {
		transform: scale(0.98);
		transform: translateY(3px);
	}

	img {
		box-sizing: border-box;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		max-height: 10rem;
		z-index: 0;
	}

	span {
		font-family: "Mochiy Pop P One";
		position: absolute;
		bottom: 1.8rem;
		left: 5rem;
		transform: translateX(-50%);
		text-transform: uppercase;
	}
}
