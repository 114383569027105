@import "./colours.scss";

.content-page {
	display: grid;
	grid-template-rows: auto minmax(0, 1fr) auto;
	padding: 1rem 1.5rem;
	gap: 1rem;
}

.back-link {
	width: max-content;
	color: $text-colour;
	text-decoration: none;
	line-height: 1;
	transition: transform 0.1s ease-in;

	&:hover {
		transform: scale(0.97);
	}

	span {
		margin-top: -1px;
	}

	img {
		margin-bottom: -1px;
		height: 15px;
	}
}

.media-container {
	display: flex;
	align-items: center;
	justify-content: center;

	.media {
		box-sizing: border-box;
		max-height: 100%;
		max-width: 100%;
		height: auto;
		width: auto;

		&:not(.loaded) {
			visibility: hidden;
		}

		&:not(:fullscreen) {
			border-radius: 25px;

			border: 4px solid $border-colour;
		}
	}
}

.buttons-container {
	display: flex;
	justify-content: space-evenly;

	.media-control-button {
		border: none;
		background: none;
		padding: 0;

		transition: transform 0.07s ease-in-out;

		@keyframes wiggle {
			from {
				transform: rotate(-3deg);
			}
			to {
				transform: rotate(3deg);
			}
		}

		&:disabled {
			filter: grayscale(1);
			pointer-events: none;
		}

		&:active {
			transform: scale(0.97);
		}

		&:hover {
			cursor: pointer;
			animation: 1s wiggle infinite alternate-reverse;
		}

		img {
			height: 50px;

			&.slideshow-button-icon {
				height: 45px;
			}

			&.next-button-icon {
				transform: rotate(90deg);
			}

			&.prev-button-icon {
				transform: rotate(-90deg);
			}
		}
	}
}

@keyframes rotation {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(359deg);
	}
}

.ollie-spinner {
	height: 50px;
	animation: rotation 1.5s infinite linear;
}

@media screen and (any-pointer: coarse) {
	.back-link {
		padding: 1rem;
	}

	.buttons-container .media-control-button {
		padding: 1rem;
	}
}
